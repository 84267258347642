// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout pageTitle="🌎 Home">
      <p>I'm learning how to build a 🧑🏻‍🎤 <a href="https://jamstack.org">JAMstack</a> website using ⽹ <a href="https://www.netlify.com">Netlify</a>, 🐙 <a href="https://github.com/gregorspamsa">GitHub</a>, 🍇 <a href="https://www.gatsbyjs.com">Gatsby</a>, 🎏 <a href="https://glitch.com/">Glitch</a>, and 🆚 <a href="https://code.visualstudio.com">Visual Studio Code</a>.</p>
      <StaticImage
        alt="Magic eye image of waves."
        src="https://images2.minutemediacdn.com/image/upload/c_fill,g_auto,h_1248,w_2220/f_auto,q_auto,w_1100/v1555929757/shape/mentalfloss/how-do-magic-eye-pictures-work_5.jpg"
      />
      {/* <StaticImage
        alt="The Gatsby Logo"
        src="../images/icon.png"
      /> */}
    </Layout>
  )
}

// Step 3: Export your component
export default IndexPage

